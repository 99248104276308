import createAvatar from '../../utils/createAvatar';
import { ProfilePictureDisplayNameSelector } from '../dashboard/user/logic/selectors';
import { MAvatar } from './@material-extend';

export default function MyAvatar({ ...other }) {
  const { profilePicture, displayName } = ProfilePictureDisplayNameSelector();

  return (
    <MAvatar
      src={profilePicture}
      alt={displayName}
      color={profilePicture ? 'default' : createAvatar(displayName).color}
      {...other}
    >
      {createAvatar(displayName).name}
    </MAvatar>
  );
}
