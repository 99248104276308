import { ERROR_MESSAGES, STATUS_CODES } from '../common';
import { PATH_PAGE } from '../routes/paths';
import history from './history';
import { convertToCamelCase, convertToSnakeCase, showErrorAlert } from './misc';
// ToDo: Obsolete this middleware to 'performApiCall'
export const apiCall =
  (
    helperMethod,
    requestData,
    initAction,
    successAction,
    errorAction,
    successData = {},
    errorData = {},
    successCallback = null,
    errorCallBack = null
  ) =>
  (dispatch) => {
    if (initAction) {
      dispatch(initAction());
    }
    const snakeCaseRequestData = convertToSnakeCase(requestData);
    return helperMethod(snakeCaseRequestData)
      .then((response) => {
        if (response.status >= STATUS_CODES.HTTP_200_OK && response.status < STATUS_CODES.HTTP_300_MULTIPLE_CHOICES) {
          if (successAction) {
            dispatch(successAction({ ...response, data: convertToCamelCase(response.data), successData }));
          }
          if (successCallback) {
            successCallback(convertToCamelCase(response.data));
          }
        } else {
          // NOTE: this code will not execute because of catch
          const error = new Error(response.statusText);
          error.response = response;
          error.errorData = errorData;

          if (errorAction) {
            dispatch(errorAction(error));
          }
          if (errorCallBack) {
            errorCallBack(error?.response?.data || ERROR_MESSAGES.SOMETHING_WENT_WRONG);
          }
        }
      })
      .catch((error) => {
        let callback = null;
        if (error.response) {
          // todo:
          // we need to change this for only GET requests.
          // these redirections does not make sense in case of requests other than GET
          switch (error.response.status) {
            case STATUS_CODES.HTTP_404_NOT_FOUND:
              callback = () => history.push(PATH_PAGE.page404);
              break;
            // 403 should not be redirected to 404.
            // 403 should indicate that user does not have the permission.
            case STATUS_CODES.HTTP_403_FORBIDDEN:
              callback = () => history.push(PATH_PAGE.page404);
              break;
            case STATUS_CODES.HTTP_500_INTERNAL_SERVER_ERROR:
              callback = () => history.push(PATH_PAGE.page500);
              break;
            // todo: Update un-authorize error redirection page
            // login failure also sends 401, dont need any redirection on that so commenting for now.
            // will look into this later
            // default case
            default:
            // default case
          }
        }
        // todo: import useSnackbar from 'notistack' here and use it to show errors
        // instead of showing errors in every component
        // console.log(Object.values(error?.response?.data) || ERROR_MESSAGES.SOMETHING_WENT_WRONG);
        if (errorAction) {
          dispatch(errorAction({ error: error?.response?.data || ERROR_MESSAGES.SOMETHING_WENT_WRONG, errorData }));
        }
        if (errorCallBack) {
          errorCallBack(error?.response?.data || ERROR_MESSAGES.SOMETHING_WENT_WRONG);
        }

        if (callback) {
          callback();
        }
      });
  };

export const performApiCall =
  (
    helperMethod,
    requestData,
    initAction,
    successAction,
    errorAction,
    initData = {},
    successData = {},
    errorData = {},
    successCallback = null,
    errorCallBack = null
  ) =>
  (dispatch) => {
    if (initAction) {
      dispatch(initAction(initData));
    }
    const snakeCaseRequestData = convertToSnakeCase(requestData);
    return helperMethod(snakeCaseRequestData)
      .then((response) => {
        if (response.status >= STATUS_CODES.HTTP_200_OK && response.status < STATUS_CODES.HTTP_300_MULTIPLE_CHOICES) {
          if (successAction) {
            dispatch(successAction({ ...response, data: convertToCamelCase(response.data), successData }));
          }
          if (successCallback) {
            successCallback();
          }
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          error.errorData = errorData;

          if (errorAction) {
            dispatch(errorAction(error));
          }
          if (errorCallBack) {
            errorCallBack();
          }
        }
      })
      .catch((error) => {
        let callback = null;
        if (error.response) {
          // todo:
          // we need to change this for only GET requests.
          // these redirections does not make sense in case of requests other than GET
          switch (error.response.status) {
            case STATUS_CODES.HTTP_404_NOT_FOUND:
              callback = () => history.push(PATH_PAGE.page404);
              break;
            case STATUS_CODES.HTTP_403_FORBIDDEN:
              callback = () => history.push(PATH_PAGE.page404);
              break;
            case STATUS_CODES.HTTP_500_INTERNAL_SERVER_ERROR:
              callback = () => showErrorAlert(ERROR_MESSAGES.INTERNAL_SERVER_ERROR_OCCURRED);
              break;
            // todo: Update un-authorize error redirection page
            case STATUS_CODES.HTTP_401_UNAUTHORIZED:
              callback = () => history.push(PATH_PAGE.page500);
              break;
            default:
            // default case
          }
        }
        if (errorAction) {
          dispatch(errorAction({ error: convertToCamelCase(error?.response?.data), errorData }));
        }
        // checking if return type is object then passing data to error callback
        // else showing toaster
        const errors = error?.response?.data;
        if (errorCallBack) {
          if (typeof errors === 'object' && errors !== null) {
            errorCallBack(convertToCamelCase(errors));
          } else {
            errorCallBack();
          }
        } else if (!callback) {
          // todo: apply .replace(/<[^>]*>/g, '') for network errors
          showErrorAlert(errors || ERROR_MESSAGES.SOMETHING_WENT_WRONG);
        }

        if (callback) {
          callback();
        }
      });
  };
