import { toast } from 'react-toastify';

import { Settings } from './components';
import { BaseOptionChartStyle } from './components/shared/charts/BaseOptionChart';
import GoogleAnalytics from './components/shared/GoogleAnalytics';
import LoadingScreen, { ProgressBarStyle } from './components/shared/LoadingScreen';
import NotistackProvider from './components/shared/NotistackProvider';
import RtlLayout from './components/shared/RtlLayout';
import ScrollToTop from './components/shared/ScrollToTop';
import ThemeLocalization from './components/shared/ThemeLocalization';
import ThemePrimaryColor from './components/shared/ThemePrimaryColor';
import useAuth from './hooks/useAuth';
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// hooks
// components

// ----------------------------------------------------------------------
toast.configure();

export default function App() {
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <GlobalStyles />
              <ProgressBarStyle />
              <BaseOptionChartStyle />
              <ScrollToTop />
              <Settings />
              <GoogleAnalytics />
              {isInitialized ? <Router /> : <LoadingScreen />}
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
