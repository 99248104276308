const API_PREFIX = 'api/';
const V1_API_PREFIX = `${API_PREFIX}v1/`;
const USER_PREFIX = 'users/';
const CUSTOMER_PREFIX = 'customers/';
const INVOICE_PREFIX = 'invoice/';
const ORDER_TEMPLATES_PREFIX = 'order/';
const LOCATION_TEMPLATES_PREFIX = 'location/';

export const apiUrls = {
  AUTHENTICATION: {
    REGISTER: `${V1_API_PREFIX}users/`,
    PASSWORD_RESET: `${V1_API_PREFIX}password_reset/`,
    NEW_PASSWORD: `${V1_API_PREFIX}password_reset/confirm/`
  },
  USER: {
    LOGIN: `${USER_PREFIX}login/`,
    REFRESH_SESSION: `${USER_PREFIX}refresh-token/`,
    ME: (id) => `${USER_PREFIX}me/${id}/`,
    CHANGE_PASSWORD: `${USER_PREFIX}change-password/`,
    EMAIL_VERIFICATION: `${V1_API_PREFIX}users/email/verification/`
  },
  CUSTOMER: {
    CUSTOMERS: (id = null) => `${CUSTOMER_PREFIX}${id ? `${id}/` : ''}`,
    DELETE_CUSTOMERS: `${CUSTOMER_PREFIX}delete-all/`,
    UPLOAD_CUSTOMERS: `${CUSTOMER_PREFIX}upload/`
  },
  INVOICE: {
    INVOICE: (id = null) => `${INVOICE_PREFIX}${id ? `${id}/` : ''}`,
    CANCEL: `${INVOICE_PREFIX}cancel/`
  },
  ORDER_TEMPLATES: {
    ORDERS: (id = null) => `${ORDER_TEMPLATES_PREFIX}${id ? `${id}/` : ''}`,
    DELETE_ORDERS: `${ORDER_TEMPLATES_PREFIX}delete-all/`,
    UPLOAD_ORDERS: `${ORDER_TEMPLATES_PREFIX}upload/`
  },
  LOCATION: {
    LOCATION: (id = null) => `${LOCATION_TEMPLATES_PREFIX}${id ? `${id}/` : ''}`
  }
};
