import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

// routes
import { PATH_DASHBOARD } from '../routes/paths';
//
import { isUserLoggedIn } from '../utils/jwt';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const isAuthenticated = isUserLoggedIn();

  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
