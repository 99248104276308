import { performApiCall } from '../../../../utils';
import {
  getCustomersHelper,
  deleteCustomerHelper,
  createCustomerHelper,
  updateCustomerHelper,
  deleteCustomersHelper,
  uploadCustomersHelper
} from './helpers';
import {
  getOrdersFail,
  getOrdersInit,
  getOrdersSuccess,
  resetOrdersSliceAction,
  updateOrderInit,
  updateOrderSuccess,
  updateOrderFail
} from './slice';

export const getOrders = (requestData) => (dispatch) =>
  dispatch(performApiCall(getCustomersHelper, requestData, getOrdersInit, getOrdersSuccess, getOrdersFail));

export const deleteOrder = (customerId, onSuccess) => (dispatch) =>
  dispatch(
    performApiCall(
      deleteCustomerHelper,
      customerId,
      updateOrderInit,
      updateOrderSuccess,
      updateOrderFail,
      null,
      null,
      null,
      onSuccess
    )
  );

export const deleteOrders = (customerIds, onSuccess) => (dispatch) =>
  dispatch(
    performApiCall(
      deleteCustomersHelper,
      customerIds,
      updateOrderInit,
      updateOrderSuccess,
      updateOrderFail,
      null,
      null,
      null,
      onSuccess
    )
  );

export const createOrder = (requestData, onSuccess) => (dispatch) =>
  dispatch(
    performApiCall(
      createCustomerHelper,
      requestData,
      updateOrderInit,
      updateOrderSuccess,
      updateOrderFail,
      null,
      null,
      null,
      onSuccess
    )
  );

export const updateOrder = (id, requestData, onSuccess) => (dispatch) =>
  dispatch(
    performApiCall(
      updateCustomerHelper,
      { id, ...requestData },
      updateOrderInit,
      updateOrderSuccess,
      updateOrderFail,
      null,
      null,
      null,
      onSuccess
    )
  );

export const uploadOrders = (requestData, onSuccess) => (dispatch) =>
  dispatch(
    performApiCall(
      uploadCustomersHelper,
      requestData,
      updateOrderInit,
      updateOrderSuccess,
      updateOrderFail,
      null,
      null,
      null,
      onSuccess
    )
  );

export const resetOrders = () => (dispatch) => dispatch(resetOrdersSliceAction());
