import { performApiCall } from '../../../../utils';
import { createLocationHelper, getLocationHelper, updateLocationHelper } from './helpers';
import {
  getLocationFail,
  getLocationInit,
  getLocationSuccess,
  resetLocationSliceAction,
  updateLocationFail,
  updateLocationInit,
  updateLocationSuccess
} from './slice';

export const getLocation = (requestData) => (dispatch) =>
  dispatch(performApiCall(getLocationHelper, requestData, getLocationInit, getLocationSuccess, getLocationFail));

export const createLocation = (requestData, onSuccess) => (dispatch) =>
  dispatch(
    performApiCall(
      createLocationHelper,
      requestData,
      updateLocationInit,
      updateLocationSuccess,
      updateLocationFail,
      null,
      null,
      null,
      onSuccess
    )
  );

export const updateLocation = (id, requestData, onSuccess) => (dispatch) =>
  dispatch(
    performApiCall(
      updateLocationHelper,
      { id, ...requestData },
      updateLocationInit,
      updateLocationSuccess,
      updateLocationFail,
      null,
      null,
      null,
      onSuccess
    )
  );

export const resetLocation = () => (dispatch) => dispatch(resetLocationSliceAction());
