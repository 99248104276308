export const Enums = {
  FORMS: {
    BASIC_INFORMATION: 'basic_information',
    ADDRESS_DETAILS: 'address_details',
    CONTACT_DETAILS: 'contact_details',
    SOCIAL_PLATFORMS: 'social_platforms'
  }
};

export const DISPLAY_MESSAGE = {
  LOCATION_CREATED_SUCCESS: 'Location created successfully',
  LOCATION_UPDATED_SUCCESS: 'Location updated successfully'
};

export const LOCATION_TYPES = [
  { id: 'PHYSICAL', name: 'Physical' },
  { id: 'MOBILE', name: 'Mobile' }
];

export const LOCATION_INIT = {
  businessName: '',
  name: '',
  description: '',
  websiteUrl: '',
  businessEmail: '',
  phoneNumber: '',
  facebookUrl: '',
  twitterUsername: '',
  instagramUsername: '',
  address: {
    country: '',
    administrativeDistrictLevel1: '',
    locality: '',
    postalCode: '',
    addressLine1: null
  }
};
