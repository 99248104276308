import { Box, Button, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { SeverErrorIllustration } from '../../../assets';
import { ERROR_MESSAGES, LABELS } from '../../../common';
import { PATH_PAGE } from '../../../routes/paths';
import Page from '../Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

const PageError = (props) => (
  <RootStyle title={LABELS.APP_TITLE(ERROR_MESSAGES.SOMETHING_WENT_WRONG)}>
    <Container>
      <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
        <Typography variant="h3" paragraph>
          {props.title}
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>{props.description}</Typography>

        <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />

        <Button to={props.buttonTo} size="large" variant="contained" component={RouterLink}>
          {props.buttonText}
        </Button>
      </Box>
    </Container>
  </RootStyle>
);

PageError.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  buttonTo: PropTypes.string
};

PageError.defaultProps = {
  title: 'Something went wrong',
  description:
    'Please accept our apologies but something has went wrong. Please feel free to report this issue to us. We`d be happy to help you out',
  buttonText: 'Go to home page',
  buttonTo: PATH_PAGE.home
};

export default PageError;
