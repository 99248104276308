import { useSelector } from '../../../../redux';

export const ProfilePictureDisplayNameSelector = () => {
  const { user } = useSelector((state) => state.user);
  const { profilePicture, displayName } = user;

  return {
    displayName,
    profilePicture
  };
};

export const UserSelector = () => {
  const { user } = useSelector((state) => state.user);

  return {
    user
  };
};

export const DisplayNameTypeSelector = () => {
  const { user } = useSelector((state) => state.user);
  const { displayName, type } = user;

  return {
    displayName,
    type
  };
};

export const DisplayNameEmailSelector = () => {
  const { user } = useSelector((state) => state.user);
  const { displayName, email } = user;

  return {
    displayName,
    email
  };
};
