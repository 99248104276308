export { default as HeaderBreadcrumbs } from './HeaderBreadcrumbs';
export { default as SlideUpTransition } from './SlideUpTransition';
export { default as Markdown } from './Markdown';
export { default as Page } from './Page';
export { default as SvgIconStyle } from './SvgIconStyle';
export { default as NavSection } from './NavSection';
export { default as Settings } from './settings';
export { default as Scrollbar } from './Scrollbar';
export { default as Logo } from './Logo';
export { default as FormReactSelect } from './FormReactSelect';
export { default as NavbarAuthButtons } from './NavbarAuthButtons';
export { default as ConfirmationDialog } from './ConfirmationDialog';
export { default as ColorPreview } from './ColorPreview';
export { default as Label } from './Label';
export { default as Banner } from './Banner';
export { default as MyAvatar } from './MyAvatar';
export { default as FormSelect } from './FormSelect';
export { default as Redirect } from './Redirect';
export { default as DatePicker } from '@mui/lab/DatePicker';
export { PageError } from './page-error';
// Use lazy loading to minimize load time
export { default as ReactSwitch } from 'react-switch';
export { Link as RouterLink } from 'react-router-dom';

export { motion } from 'framer-motion';
export { default as Slider } from 'react-slick';

export * from './carousel';
export * from './@material-extend';
export * from './animate';
export * from './_external-pages';
export * from './common';
export * from './materialUI';
export * from './iconify';
export * from './upload';
