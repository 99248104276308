import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  orders: [],
  offset: null,

  isLoadingOrders: false,
  isUpdatingOrders: false,
  hasNext: false,

  errors: {},
  updateErrors: {}
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    // Orders list
    getOrdersInit(state, action) {
      state.isLoadingOrders = true;

      const { isFirstPage } = action.payload;
      if (isFirstPage) {
        state.orders = [];
        state.errors = null;
        state.hasNext = false;
      }
    },

    getOrdersFail(state, action) {
      state.isLoadingOrders = false;
      state.errors = action.payload.error;
      state.hasNext = false;
    },

    getOrdersSuccess(state, action) {
      state.isLoadingOrders = false;
      state.orders = action.payload.data.results;
      state.hasNext = Boolean(action.payload.data.next);
    },

    // Order Update
    // eslint-disable-next-line no-unused-vars
    updateOrderInit(state, _) {
      state.isUpdatingOrders = true;
    },

    updateOrderFail(state, action) {
      state.isUpdatingOrders = false;
      state.updateErrors = action.payload.error;
    },

    // eslint-disable-next-line no-unused-vars
    updateOrderSuccess(state, _) {
      state.isUpdatingOrders = false;
    },

    resetOrders(state) {
      state.isLoadingOrders = false;
      state.orders = [];
      state.updateErrors = {};
    }
  }
});

// Reducer
export const { reducer: OrderReducer } = slice;

// Order Lists Actions
export const { getOrdersInit, getOrdersSuccess, getOrdersFail, resetOrders: resetOrdersSliceAction } = slice.actions;

// Order Update Actions
export const { updateOrderInit, updateOrderSuccess, updateOrderFail } = slice.actions;
