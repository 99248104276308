import { apiUrls } from '../../../../common';
import { apiGet, apiPost } from '../../../../utils';

const { INVOICE: INVOICE_MODULE } = apiUrls;

export const getInvoicesHelper = (requestData) => apiGet(INVOICE_MODULE.INVOICE(), requestData);

export const getInvoiceHelper = (id) => apiGet(INVOICE_MODULE.INVOICE(id));

export const cancelInvoiceHelper = (requestData) => apiPost(INVOICE_MODULE.CANCEL, requestData);

export const createInvoiceHelper = (requestData) => apiPost(INVOICE_MODULE.INVOICE(), requestData);
