import { performApiCall } from '../../../../utils';
import { cancelInvoiceHelper, getInvoiceHelper, getInvoicesHelper, createInvoiceHelper } from './helpers';
import {
  getInvoicesFail,
  getInvoicesInit,
  getInvoicesSuccess,
  resetInvoicesSliceAction,
  getInvoiceFail,
  getInvoiceInit,
  getInvoiceSuccess,
  resetInvoiceSliceAction,
  updateInvoiceFail,
  updateInvoiceInit,
  updateInvoiceSuccess,
  createInvoiceInit,
  createInvoiceSuccess,
  createInvoiceFail
} from './slice';

export const getInvoices = (requestData) => (dispatch) =>
  dispatch(performApiCall(getInvoicesHelper, requestData, getInvoicesInit, getInvoicesSuccess, getInvoicesFail));

export const getInvoice = (id) => (dispatch) =>
  dispatch(performApiCall(getInvoiceHelper, id, getInvoiceInit, getInvoiceSuccess, getInvoiceFail));

export const cancelInvoice = (requestData, onSuccess, onError) => (dispatch) =>
  dispatch(
    performApiCall(
      cancelInvoiceHelper,
      requestData,
      updateInvoiceInit,
      updateInvoiceSuccess,
      updateInvoiceFail,
      null,
      null,
      null,
      onSuccess,
      onError
    )
  );

export const createInvoice = (requestData, onSuccess) => (dispatch) =>
  dispatch(
    performApiCall(
      createInvoiceHelper,
      requestData,
      createInvoiceInit,
      createInvoiceSuccess,
      createInvoiceFail,
      null,
      null,
      null,
      onSuccess
    )
  );

export const cancelInvoices = cancelInvoice;

export const resetInvoices = () => (dispatch) => dispatch(resetInvoicesSliceAction());

export const resetInvoice = () => (dispatch) => dispatch(resetInvoiceSliceAction());
