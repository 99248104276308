import { appCurrency, supportEmail } from '../config';

export const LABELS = {
  LOCATION_BUSINESS_NAME: 'Location/Business Name',
  BUSINESS_DETAILS: 'Business Details',
  ORDER_DETAILS: 'Order Details',
  INVOICE_DETAILS: 'Invoice Details',
  REMINDER_DETAILS: 'Reminder Details',
  NICK_NAME: 'Nick Name',
  SENT_AT: 'Sent At',
  INVOICE_TO: 'Invoice to',
  SCHEDULED_AT: 'Scheduled At',
  BUSINESS_BIO: 'Business Bio',
  WEBSITE_URL: 'Website URL',
  CONTACT_NUMBER: 'Contact Number',
  EMAIL_ADDRESS: 'Email Address',
  OPTIONAL_FACEBOOK_URL: 'Facebook URL (if any)',
  OPTIONAL_TWITTER_USERNAME: 'Twitter Username (if any)',
  OPTIONAL_INSTAGRAM_USERNAME: 'Instagram Username (if any)',
  ALL: 'All',
  UNPAID: 'Un-Paid',
  DRAFT: 'Draft',
  CLOSE: 'Close',
  REMINDER: 'Reminder',
  ADD_RECIPIENT_S: 'Add Recipient(s)',
  ADD_REMOVE_RECIPIENT_S: 'Add/Remove Recipient(s)',
  ADD: 'Add',
  SELECT: 'Select',
  CHOOSE: 'Choose',
  REMOVE: 'Remove',
  RECIPIENTS: 'Recipients',
  RECIPIENT_S: 'Recipient(s)',
  MESSAGE: 'Message',
  DAYS: 'Days',
  IF_ANY: 'If Any',
  TAXES: 'Taxes',
  DISCOUNTS: 'Discounts',
  TOTAL: 'Total',
  DISCOUNT: 'Discount',
  CURRENCY: 'Currency',
  STATUS: 'Status',
  INVOICE_NUM: 'Invoice #',
  DUE_DATE: 'Due Date',
  RECIPIENT: 'Recipient',
  PAID: 'Paid',
  TITLE: 'Title',
  CITY: 'City',
  POSTAL_CODE: 'Postal Code',
  ADDRESS_LINE: 'Address Line',
  QUICK_UPDATE: 'Quick Update',
  ADDRESS_DETAILS: 'Address Details',
  SOCIAL_PLATFORMS: 'Social Platforms',
  CONTACT_DETAILS: 'Contact Details',
  BASIC_INFORMATION: 'Basic Information',
  NEXT: 'Next',
  PREVIOUS: 'Previous',
  LOCATION: 'Location',
  DISCOUNT_DETAILS: 'Discount Details',
  TAX_DETAILS: 'Tax Details',
  TAX: 'Tax',
  SERVICE_CHARGES: 'Service Charges',
  PERCENTAGE: 'Percentage',
  NEW_ORDER: 'New Order',
  TAX_PERCENT: 'Tax(%)',
  DISCOUNT_NAME: 'Discount Name',
  DISCOUNT_PERCENT: 'Discount(%)',
  TAX_NAME: 'Tax Name',
  AMOUNT: 'Amount',
  REFRESH_ORDERS: 'Refresh Orders',
  REFRESH_CUSTOMERS: 'Refresh Customers',
  REFRESH_INVOICE: 'Refresh Invoice',
  DOB: 'DOB',
  UPLOAD: 'Upload',
  SAMPLE_FILE: 'sample file',
  ADD_CUSTOMER: 'Add Customer',
  ADD_ORDER: 'Add Order',
  EDIT_CUSTOMER: 'Edit Customer',
  EDIT_ORDER: 'Edit Order',
  CREATE: 'Create',
  CREATED_AT: 'Created At',
  NOTE: 'Note',
  ADDRESS: 'Address',
  PHONE_NUMBER: 'Phone Number',
  NEW_CUSTOMER: 'New Customer',
  NEW_INVOICE: 'New Invoice',
  HASH: '#',
  CONTACT: 'Contact',
  NOTE_COMMENTS: 'Note/Comments',
  NO_RECORD: 'No Record',
  WEB_MAIL: 'Web Mail',
  LOCATION_OR_BUSINESS: 'Location/Business',
  INVOICES: 'Invoices',
  ORDER_TEMPLATES: 'Order Templates',
  CUSTOMERS: 'Customers',
  CREATE_INVOICE: 'Create Invoice',
  ALL_INVOICES: 'All Invoices',
  CREATE_ORDER: 'Create Order',
  ALL_ORDERS: 'All Orders',
  DELETE: 'Delete',
  UPDATE: 'Update',
  DECLINE: 'Decline',
  APPLY: 'Apply',
  RESET: 'Reset',
  APP_NAME: 'Grey Rock Consulting',
  COMPANY_NAME: 'Grey Rock Consulting',
  APP_TITLE: (subTitle) => `GRC | ${subTitle}`,
  DASHBOARD_TITLE: (subTitle) => `GRC | Dashboard | ${subTitle}`,
  HOME: 'Home',
  LOG_IN: 'Log in',
  SIGN_UP: 'Sign Up',
  FACEBOOK: 'FaceBook',
  GOOGLE: 'Google',
  LINKEDIN: 'Linkedin',
  INSTAGRAM: 'Instagram',
  TWITTER: 'Twitter',
  FAQS: 'FAQs',
  CONTACT_US: 'Contact Us',
  PRIVACY: 'Privacy Policy',
  TERMS_AND_CONDITIONS: 'Terms And Conditions',
  TERMS_AMPERSAND_CONDITIONS: 'Terms & Conditions',
  LEGAL: 'Legal',
  RECENT_POSTS: 'Recent Posts',
  BUY_CARBON_OFFSETS: 'Buy Carbon Offsets',
  VERIFIED_BY: 'Verified by',
  COMMENTS: 'Comments',
  DASHBOARD: 'Dashboard',
  WHAT_IS: 'What is',
  NAME: 'Name',
  EMAIL: 'Email',
  SUBJECT: 'Subject',
  SUBMIT: 'Submit',
  SUBMIT_NOW: 'Submit Now',
  CANCEL: 'Cancel',
  EXPORT: 'Export',
  REQUIRED: 'Required',
  LOGIN: 'Login',
  REGISTER: 'Register',
  RESET_PASSWORD: 'Password Reset',
  NEW_PASSWORD: 'Set New Password',
  LOGOUT: 'Logout',
  USER_PROFILE: 'User Profile',
  GET_STARTED: 'Get Started',
  FREQUENTLY_ASKED_QUESTIONS: 'Frequently Asked Questions',
  SEARCH: 'Search',
  COUNTRY: 'Country',
  COUNTRIES: 'Countries',
  STATE: 'State/Region',
  TRANSPARENCY: 'Transparency',
  REPLY: 'Reply',
  TONNES: 'tonnes',
  EMAIL_VERIFICATION: 'Email Verification',
  ONE_TIME_PURCHASE: 'One-time Purchase',
  SUBSCRIBE: 'Subscribe',
  TIKTOK: 'Tiktok',
  TERMS: 'Terms',
  AND: 'And',
  AMPERSAND: '&',
  CONDITIONS: 'Conditions',
  UPDATED: 'Updated',
  HAVE_QUESTIONS: 'Have Questions',
  COMING_SOON: 'Coming Soon',
  SAVE_CHANGES: 'Save Changes',
  LEARN_MORE: 'Learn More',
  TYPE: 'Type',
  DESCRIPTION: 'Description',
  VIEW: 'View',
  EDIT: 'Edit',
  V: 'V',
  ORDER: 'Order',
  ORDERS: 'Orders',
  PAYMENT_SUCCESSFUL: 'Payment Successful',
  IN_PROCESS: 'In Process',
  COMPLETED: 'Completed',
  CANCELED: 'Canceled'
};

export const SUPPORT_EMAIL = supportEmail || 'help@aspectv.com';
export const APP_CURRENCY = appCurrency || 'USD';

export const ERROR_MESSAGES = {
  INVALID_LOGIN_CREDENTIALS: 'Invalid username or password. Please try again.',
  INVALID_EMAIL_ADDRESS: 'Please enter a valid email address',
  SOMETHING_WENT_WRONG: 'Oops! Something went wrong',
  INVALID_URL: 'Invalid URL',
  REQUIRED: 'Required'
};

export const SUCCESS_MESSAGES = {};

export const DISPLAY_STRINGS = {};

export const CONSTANTS = {
  INITIAL_PAGE: 0,
  INITIAL_STEP: 0,
  INITIAL_OFFSETS: [],
  ROWS_PER_PAGE: 100
};

export const DATA_TYPE = {
  INDEX: 'index',
  PRICE: 'price',
  AMOUNT: 'amount',
  CLICKABLE_ACTION: 'clickable_action',
  PERCENTAGE: 'percentage',
  NUMBER: 'number',
  TEXT: 'text',
  DATE: 'date',
  STATE: 'state',
  NAME: 'name',
  ACTION: 'action',
  CHECKBOX: 'checkbox',
  PARAGRAPH: 'paragraph'
};

export const REGEX = {
  URL: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/
};

export const DEFAULT_USER_PROFILE = {
  PICTURE:
    'https://img.favpng.com/25/13/19/samsung-galaxy-a8-a8-user-login-telephone-avatar-png-favpng-dqKEPfX7hPbc6SMVUCteANKwj.jpg',
  TYPE: 'ASPECT V User'
};

export const DISPLAY_MESSAGE = {
  ARE_YOU_SURE: 'Are you sure?'
};
