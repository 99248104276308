export const formatInvoiceCreateErrors = (errors) => ({
  title: errors.title,
  description: errors.description,
  recipients: errors.customerIds,
  ...(errors?.paymentRequests?.length
    ? {
        dueDate: errors.paymentRequests[0].dueDate,
        ...(errors.paymentRequests[0].reminders?.length
          ? {
              remindersDays: errors.paymentRequests[0].reminders[0].relativeScheduledDays,
              remindersMessage: errors.paymentRequests[0].reminders[0].message
            }
          : {})
      }
    : {}),
  ...(errors?.taxes?.length ? { taxName: errors.taxes[0].name, taxPercentage: errors.taxes[0].percentage } : {}),
  ...(errors?.discounts?.length
    ? { taxName: errors.discounts[0].name, discountPercentage: errors.discounts[0].percentage }
    : {}),
  ...(errors?.lineItems?.length
    ? {
        orderName: errors.lineItems[0].name,
        amount: errors.lineItems[0].basePriceMoney?.amount
      }
    : {})
});
