import { useSelector } from '../../../../redux';
import { CustomersSelector } from '../../customer';

export const InvoicesSelector = () => {
  const { invoices, isLoadingInvoices, offset: nextOffset, isUpdatingInvoices } = useSelector((state) => state.invoice);

  return { invoices, isLoadingInvoices, nextOffset, isUpdating: isUpdatingInvoices };
};

export const InvoiceSelector = () => {
  const { invoice, isLoadingInvoice } = useSelector((state) => state.invoice);

  return { invoice, isLoadingInvoice };
};

export const InvoiceCreateSelector = () => {
  const { isCreatingInvoice, errors } = useSelector((state) => state.invoice);

  return { isCreating: isCreatingInvoice, errors };
};

export const RecipientsSelector = () => {
  const { customers, isLoadingCustomers, ...rest } = CustomersSelector();

  return { recipients: customers, isLoadingRecipients: isLoadingCustomers, ...rest };
};
