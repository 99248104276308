import { apiUrls } from '../../../../common';
import { apiGet, apiDelete, apiPost, apiPatch } from '../../../../utils';

const { CUSTOMER: CUSTOMER_MODULE } = apiUrls;

export const getCustomersHelper = (requestData) => apiGet(CUSTOMER_MODULE.CUSTOMERS(), requestData);

export const deleteCustomerHelper = (customerId) => apiDelete(CUSTOMER_MODULE.CUSTOMERS(customerId));

export const deleteCustomersHelper = (customerIds) => apiPost(CUSTOMER_MODULE.DELETE_CUSTOMERS, customerIds);

export const createCustomerHelper = (requestData) => apiPost(CUSTOMER_MODULE.CUSTOMERS(), requestData);

export const updateCustomerHelper = ({ id, ...requestData }) => apiPatch(CUSTOMER_MODULE.CUSTOMERS(id), requestData);

export const uploadCustomersHelper = (requestData) => apiPost(CUSTOMER_MODULE.UPLOAD_CUSTOMERS, requestData);
