import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  customers: [],
  offset: null,

  isLoadingCustomers: false,
  isUpdatingCustomers: false,

  errors: {}
};

const slice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    // Customers list
    getCustomersInit(state, action) {
      state.isLoadingCustomers = true;

      const { isFirstPage } = action.payload;
      if (isFirstPage) {
        state.customers = [];
        state.offset = null;
        state.errors = null;
      }
    },

    getCustomersFail(state, action) {
      state.isLoadingCustomers = false;
      state.errors = action.payload;
    },

    getCustomersSuccess(state, action) {
      const { customers, offset } = action.payload.data;
      state.isLoadingCustomers = false;
      state.customers = customers;
      state.offset = offset;
    },

    // Customer Update
    // eslint-disable-next-line no-unused-vars
    updateCustomerInit(state, _) {
      state.isUpdatingCustomers = true;
    },

    updateCustomerFail(state, action) {
      state.isUpdatingCustomers = false;
      state.errors = action.payload;
    },

    // eslint-disable-next-line no-unused-vars
    updateCustomerSuccess(state, _) {
      state.isUpdatingCustomers = false;
    },

    resetCustomers(state) {
      state.isLoadingCustomers = false;
      state.customers = [];
      state.errors = null;
      state.offset = null;
    }
  }
});

// Reducer
export const { reducer: CustomerReducer } = slice;

// Customer Lists Actions
export const {
  getCustomersInit,
  getCustomersSuccess,
  getCustomersFail,
  resetCustomers: resetCustomersSliceAction
} = slice.actions;

// Customer Update Actions
export const { updateCustomerInit, updateCustomerSuccess, updateCustomerFail } = slice.actions;
