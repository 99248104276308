import { useSelector } from '../../../../redux';

export const CustomersSelector = () => {
  const {
    customers,
    isLoadingCustomers,
    offset: nextOffset,
    isUpdatingCustomers
  } = useSelector((state) => state.customer);

  return { customers, isLoadingCustomers, nextOffset, isUpdating: isUpdatingCustomers };
};
