import PropTypes from 'prop-types';
import Select from 'react-select';

import { LABELS } from '../../common';

// ----------------------------------------------------------------------

const FormReactSelect = ({ placeholder, overrideStyles, name, ...others }) => {
  const customStyles = {
    option: (base) => ({
      ...base,
      textAlign: 'left'
    }),
    placeholder: (base) => ({
      ...base,
      textAlign: 'left'
    }),
    singleValue: (base) => ({
      ...base,
      textAlign: 'left'
    })
  };

  return (
    <Select
      {...others}
      placeholder={placeholder}
      name={name}
      styles={{ ...customStyles, ...overrideStyles }}
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
    />
  );
};

FormReactSelect.propTypes = {
  overrideStyles: PropTypes.object,

  name: PropTypes.string,
  placeholder: PropTypes.string
};

FormReactSelect.defaultProps = {
  placeholder: LABELS.SEARCH,
  name: 'search'
};

export default FormReactSelect;
