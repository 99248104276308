import { createSlice } from '@reduxjs/toolkit';

import { storeUserInLocalStorage, getLoggedInUser } from '../../../../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  user: getLoggedInUser()
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateProfileSuccess(state, action) {
      const previousUser = getLoggedInUser();
      const user = { ...previousUser, ...action.payload.data };
      const localStorageUserData = storeUserInLocalStorage(user);
      state.user = localStorageUserData;
    },

    LoginSuccess(state, action) {
      const payloadData = action.payload.data;
      const localStorageUserData = storeUserInLocalStorage(payloadData);
      state.user = localStorageUserData;
    }
  }
});

// Reducer
export const { reducer: UserReducer } = slice;

// Update profile action
export const { updateProfileSuccess } = slice.actions;

// Authentication/Login/Register action
export const { LoginSuccess } = slice.actions;
