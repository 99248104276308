import { sign, verify } from 'jsonwebtoken';
import jwtDecode from 'jwt-decode';

import { DEFAULT_USER_PROFILE } from '../common/constants';
//
import axios from './axios';
// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export const setLocalSession = (user) =>
  user ? localStorage.setItem('user', JSON.stringify(user)) : localStorage.removeItem('user');

// our custom functions

export const isUserLoggedIn = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    return user;
  }
  return false;
};

// todo: move constants somewhere else

export const getLoggedInUser = () => JSON.parse(localStorage.getItem('user'));

// this function will be responsible for returning valid token, if token is expired then this function
// will itself refresh the token and return the valid one.
export const getToken = () => {
  const user = isUserLoggedIn();
  if (user) {
    return user.access;
  }
  return null;
};

export const storeUserInLocalStorage = (data) => {
  // combining first and last name to make a full/display name
  if (data.firstName) {
    data.displayName = data.firstName;
  }
  if (data.lastName) {
    data.displayName += ` ${data.lastName}`;
  }
  // setting profile picture
  if (!data.profilePicture) {
    data.profilePicture = DEFAULT_USER_PROFILE.PICTURE;
  }

  let dataToStore = data;
  const user = localStorage.getItem('user');
  if (user) {
    dataToStore = { ...JSON.parse(user), ...data };
    localStorage.setItem('user', JSON.stringify(dataToStore));
    return dataToStore;
  }

  localStorage.setItem('user', JSON.stringify(dataToStore));
  return dataToStore;
};

export { verify, sign };
