import { useEffect } from 'react';

import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

const Redirect = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;
};

Redirect.propTypes = {
  to: PropTypes.string.isRequired
};

export default Redirect;
