import { LABELS } from '../../common';
import SvgIconStyle from '../../components/shared/SvgIconStyle';
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  order: getIcon('ic_ecommerce'),
  invoice: getIcon('ic_invoice'),
  customer: getIcon('ic_customers'),
  location: getIcon('ic_location')
};

const sidebarConfig = [
  {
    subheader: 'management',
    items: [
      {
        title: 'profile',
        path: PATH_DASHBOARD.user.account,
        icon: ICONS.user
      },
      {
        title: LABELS.CUSTOMERS,
        path: PATH_DASHBOARD.customer.root,
        icon: ICONS.customer
      },
      {
        title: LABELS.INVOICES,
        path: PATH_DASHBOARD.temporary.root,
        icon: ICONS.invoice,
        children: [
          { title: LABELS.CREATE_INVOICE, path: PATH_DASHBOARD.invoice.create },
          { title: LABELS.ALL_INVOICES, path: PATH_DASHBOARD.invoice.root }
        ]
      },
      {
        title: LABELS.ORDER_TEMPLATES,
        path: PATH_DASHBOARD.order.root,
        icon: ICONS.order
      },
      {
        title: LABELS.LOCATION_OR_BUSINESS,
        path: PATH_DASHBOARD.location.root,
        icon: ICONS.location
      },
      {
        title: LABELS.WEB_MAIL,
        path: PATH_DASHBOARD.webMail.root,
        icon: ICONS.mail
      }
    ]
  }
];

export default sidebarConfig;
