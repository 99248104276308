import { MenuItem } from '../utils';

export const TOASTER_SUCCESS_TIME = 3000;
export const TOASTER_ERROR_TIME = 5000;

export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';

export const FRONT_END_FIELD_DATE_FORMAT = 'dd/MM/yyyy';

export const getNestedValue = (obj, key) => {
  const keys = key.split('.');
  let value = obj;

  keys.some((key) => {
    value = value[key];
    if (value === undefined) {
      return true;
    }

    return false;
  });

  return value;
};

export const getMenuOptions = (options) =>
  options.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ));

const convertToCSV = (data) => data.map((entry) => entry.join(',')).join('\n');

export const downloadCSV = (data, filename = 'sample') => {
  const csvData = convertToCSV(data);
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename}.csv`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const removeEmptyValues = (obj) => Object.fromEntries(Object.entries(obj).filter(([key, value]) => value)); // eslint-disable-line no-unused-vars
