import PropTypes from 'prop-types';

import useSettings from '../../hooks/useSettings';
import darkLogo from '../../img/main/logo-dark.png';
import lightLogo from '../../img/main/logo-light.png';
import smallLogo from '../../img/main/small-logo.png';
import { Box } from '../../utils';

const Logo = ({ sx, isSmall }) => {
  const { themeMode } = useSettings();
  let logoSrc;

  if (isSmall) {
    logoSrc = smallLogo;
  } else {
    logoSrc = themeMode === 'dark' ? darkLogo : lightLogo;
  }

  return (
    <Box sx={{ width: 120, ...(isSmall ? { width: 50, height: 50 } : {}), ...sx }}>
      <img src={logoSrc} alt="logo" />
    </Box>
  );
};

Logo.propTypes = {
  sx: PropTypes.object,
  isSmall: PropTypes.bool
};

Logo.defaultProps = {
  sx: {},
  isSmall: false
};

export default Logo;
