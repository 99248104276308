import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// import userReducer from './slices/user';
import { CustomerReducer } from '../components/dashboard/customer/logic/slice';
import { InvoiceReducer } from '../components/dashboard/invoice';
import { LocationReducer } from '../components/dashboard/location';
import { OrderReducer } from '../components/dashboard/order-templates/logic/slice';
import { UserReducer } from '../components/dashboard/user/logic/slice';
import calendarReducer from './slices/calendar';
import chatReducer from './slices/chat';
import kanbanReducer from './slices/kanban';
import mailReducer from './slices/mail';
import productReducer from './slices/product';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  user: UserReducer,
  customer: CustomerReducer,
  invoice: InvoiceReducer,
  order: OrderReducer,
  location: LocationReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer)
});

export { rootPersistConfig, rootReducer };
