import { getLoggedInUser } from './jwt';

export const isPermissionGranted = (permissions) => {
  // if no permissions found, so no permission applied so allow all
  if (!permissions) {
    return true;
  }

  const user = getLoggedInUser();

  if (!user) {
    return false;
  }
  // todo: check if user.status==superuser then return true
  const currentRole = user.type;
  if (permissions.includes(currentRole)) {
    return true;
  }
  return false;
};

export const getLoggedInUserID = () => {
  const user = getLoggedInUser();
  if (user && user.id) {
    return user.id;
  }
  return null;
};
