import { COUNTRIES_WITH_CODE } from '../../../../common';
import { useSelector } from '../../../../redux';
import { LOCATION_TYPES } from '../constants';

export const LocationsSelector = () => {
  const {
    location,
    isLoadingLocation,
    offset: nextOffset,
    isUpdatingLocation,
    hasNext,
    updateErrors
  } = useSelector((state) => state.location);

  const countries = COUNTRIES_WITH_CODE.map((country) => ({
    value: country.code,
    label: `${country.name} - ${country.code}`
  }));

  const types = LOCATION_TYPES.map((type) => ({
    value: type.id,
    label: type.name
  }));

  return {
    types,
    countries,
    location,
    isLoading: isLoadingLocation,
    nextOffset,
    isUpdating: isUpdatingLocation,
    hasNext,
    updateErrors
  };
};
