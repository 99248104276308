import { performApiCall } from '../../../../utils';
import {
  getCustomersHelper,
  deleteCustomerHelper,
  createCustomerHelper,
  updateCustomerHelper,
  deleteCustomersHelper,
  uploadCustomersHelper
} from './helpers';
import {
  getCustomersFail,
  getCustomersInit,
  getCustomersSuccess,
  resetCustomersSliceAction,
  updateCustomerInit,
  updateCustomerSuccess,
  updateCustomerFail
} from './slice';

export const getCustomers = (requestData) => (dispatch) =>
  dispatch(performApiCall(getCustomersHelper, requestData, getCustomersInit, getCustomersSuccess, getCustomersFail));

export const deleteCustomer = (customerId, onSuccess) => (dispatch) =>
  dispatch(
    performApiCall(
      deleteCustomerHelper,
      customerId,
      updateCustomerInit,
      updateCustomerSuccess,
      updateCustomerFail,
      null,
      null,
      null,
      onSuccess
    )
  );

export const deleteCustomers = (customerIds, onSuccess) => (dispatch) =>
  dispatch(
    performApiCall(
      deleteCustomersHelper,
      customerIds,
      updateCustomerInit,
      updateCustomerSuccess,
      updateCustomerFail,
      null,
      null,
      null,
      onSuccess
    )
  );

export const createCustomer = (requestData, onSuccess) => (dispatch) =>
  dispatch(
    performApiCall(
      createCustomerHelper,
      requestData,
      updateCustomerInit,
      updateCustomerSuccess,
      updateCustomerFail,
      null,
      null,
      null,
      onSuccess
    )
  );

export const updateCustomer = (id, requestData, onSuccess) => (dispatch) =>
  dispatch(
    performApiCall(
      updateCustomerHelper,
      { id, ...requestData },
      updateCustomerInit,
      updateCustomerSuccess,
      updateCustomerFail,
      null,
      null,
      null,
      onSuccess
    )
  );

export const uploadCustomers = (requestData, onSuccess) => (dispatch) =>
  dispatch(
    performApiCall(
      uploadCustomersHelper,
      requestData,
      updateCustomerInit,
      updateCustomerSuccess,
      updateCustomerFail,
      null,
      null,
      null,
      onSuccess
    )
  );

export const resetCustomers = () => (dispatch) => dispatch(resetCustomersSliceAction());
