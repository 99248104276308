import { performApiCall } from '../../../../utils';
import { changePasswordHelper, updateProfileHelper } from './helpers';
import { updateProfileSuccess } from './slice';

export const submitChangePassword = (requestData, onSuccess, onFailure) => (dispatch) => {
  dispatch(performApiCall(changePasswordHelper, requestData, null, null, null, null, null, null, onSuccess, onFailure));
};

export const submitUpdateProfile = (requestData, onSuccess, onFailure) => (dispatch) => {
  dispatch(
    performApiCall(
      updateProfileHelper,
      requestData,
      null,
      updateProfileSuccess,
      null,
      null,
      null,
      null,
      onSuccess,
      onFailure
    )
  );
};
