import { createSlice } from '@reduxjs/toolkit';

import { LOCATION_INIT } from '../constants';

// ----------------------------------------------------------------------

const initialState = {
  location: LOCATION_INIT,

  isLoadingLocation: false,
  isUpdatingLocation: false,

  errors: {},
  updateErrors: {}
};

const slice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    // Location Fetch
    // eslint-disable-next-line no-unused-vars
    getLocationInit(state, _) {
      state.isLoadingLocation = true;
    },

    getLocationFail(state, action) {
      state.isLoadingLocation = false;
      state.location = LOCATION_INIT;
      state.errors = action.payload.error;
    },

    getLocationSuccess(state, action) {
      state.isLoadingLocation = false;
      state.location = action.payload.data;
    },

    // Location Update
    // eslint-disable-next-line no-unused-vars
    updateLocationInit(state, _) {
      state.isUpdatingLocation = true;
    },

    updateLocationFail(state, action) {
      state.isUpdatingLocation = false;
      state.updateErrors = action.payload.error;
    },

    updateLocationSuccess(state, action) {
      state.isUpdatingLocation = false;
      state.location = action.payload.data;
    },

    resetLocation(state) {
      state.isLoadingLocation = false;
      state.location = {};
      state.updateErrors = {};
    }
  }
});

// Reducer
export const { reducer: LocationReducer } = slice;

// Location Lists Actions
export const {
  getLocationInit,
  getLocationSuccess,
  getLocationFail,
  resetLocation: resetLocationSliceAction
} = slice.actions;

// Location Update Actions
export const { updateLocationInit, updateLocationSuccess, updateLocationFail } = slice.actions;
