import { apiUrls } from '../../../../common';
import { apiGet, apiDelete, apiPost, apiPatch } from '../../../../utils';

const { ORDER_TEMPLATES: ORDER_TEMPLATES_MODULE } = apiUrls;

export const getCustomersHelper = (requestData) => apiGet(ORDER_TEMPLATES_MODULE.ORDERS(), requestData);

export const deleteCustomerHelper = (customerId) => apiDelete(ORDER_TEMPLATES_MODULE.ORDERS(customerId));

export const deleteCustomersHelper = (customerIds) => apiPost(ORDER_TEMPLATES_MODULE.DELETE_ORDERS, customerIds);

export const createCustomerHelper = (requestData) => apiPost(ORDER_TEMPLATES_MODULE.ORDERS(), requestData);

export const updateCustomerHelper = ({ id, ...requestData }) =>
  apiPatch(ORDER_TEMPLATES_MODULE.ORDERS(id), requestData);

export const uploadCustomersHelper = (requestData) => apiPost(ORDER_TEMPLATES_MODULE.UPLOAD_ORDERS, requestData);
