import { lazy } from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import { Login, Logout, NewPassword, ResetPassword } from '../components/authentication';
import { Customer } from '../components/dashboard/customer';
import { Invoices, InvoiceCreate } from '../components/dashboard/invoice';
import { Location } from '../components/dashboard/location';
import { Order } from '../components/dashboard/order-templates';
import { UserAccount } from '../components/dashboard/user';
import Redirect from '../components/shared/Redirect';
import { webMailLink } from '../config';
import { GuestGuard, AuthGuard } from '../guards';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import MainLayout from '../layouts/main';
import { Loadable } from '../utils';
import { PATH_DASHBOARD } from './paths';

// ----------------------------------------------------------------------

// todo (IMPORTANT): I think it's a good practice to NOT include hyphens in url, so a url
// should only have / between it. This thing can be confirmed and urls can be changed accordingly.

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'logout', element: <Logout /> }
      ]
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/user/account" replace /> },
        { path: PATH_DASHBOARD.webMail.root, element: <Redirect to={webMailLink} /> },
        { path: PATH_DASHBOARD.customer.root, element: <Customer /> },
        { path: PATH_DASHBOARD.order.root, element: <Order /> },
        { path: PATH_DASHBOARD.location.root, element: <Location /> },
        { path: PATH_DASHBOARD.invoice.root, element: <Invoices /> },
        { path: PATH_DASHBOARD.invoice.create, element: <InvoiceCreate /> },
        // { path: 'ecommerce', element: <GeneralEcommerce /> },
        // { path: 'analytics', element: <GeneralAnalytics /> },
        // { path: 'banking', element: <GeneralBanking /> },
        // { path: 'booking', element: <GeneralBooking /> },

        // {
        //   path: 'e-commerce',
        //   children: [
        //     { element: <Navigate to="/dashboard/e-commerce/shop" replace /> },
        //     { path: 'shop', element: <EcommerceShop /> },
        //     { path: 'product/:name', element: <EcommerceProductDetails /> },
        //     { path: 'list', element: <EcommerceProductList /> },
        //     { path: 'product/new', element: <EcommerceProductCreate /> },
        //     { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
        //     { path: 'checkout', element: <EcommerceCheckout /> },
        //     { path: 'invoice', element: <EcommerceInvoice /> }
        //   ]
        // },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/account" replace /> },
            // ,
            // { path: 'profile', element: <UserProfile /> },
            // { path: 'cards', element: <UserCards /> },
            // { path: 'list', element: <UserList /> },
            // { path: 'new', element: <UserCreate /> },
            // { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> }
          ]
        }
        // ,
        // {
        //   path: 'mail',
        //   children: [
        //     { element: <Navigate to="/dashboard/mail/all" replace /> },
        //     { path: 'label/:customLabel', element: <Mail /> },
        //     { path: 'label/:customLabel/:mailId', element: <Mail /> },
        //     { path: ':systemLabel', element: <Mail /> },
        //     { path: ':systemLabel/:mailId', element: <Mail /> }
        //   ]
        // },
        // {
        //   path: 'chat',
        //   children: [
        //     { element: <Chat /> },
        //     { path: 'new', element: <Chat /> },
        //     { path: ':conversationKey', element: <Chat /> }
        //   ]
        // },
        // { path: 'calendar', element: <Calendar /> },
        // { path: 'kanban', element: <Kanban /> }
      ]
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
        // {
        //   path: 'components',
        //   children: [
        //     { element: <ComponentsOverview /> },
        //     // FOUNDATIONS
        //     { path: 'color', element: <Color /> },
        //     { path: 'typography', element: <Typography /> },
        //     { path: 'shadows', element: <Shadows /> },
        //     { path: 'grid', element: <Grid /> },
        //     { path: 'icons', element: <Icons /> },
        //     // MATERIAL UI
        //     { path: 'accordion', element: <Accordion /> },
        //     { path: 'alert', element: <Alert /> },
        //     { path: 'autocomplete', element: <Autocomplete /> },
        //     { path: 'avatar', element: <Avatar /> },
        //     { path: 'badge', element: <Badge /> },
        //     { path: 'breadcrumbs', element: <Breadcrumb /> },
        //     { path: 'buttons', element: <Buttons /> },
        //     { path: 'checkbox', element: <Checkbox /> },
        //     { path: 'chip', element: <Chip /> },
        //     { path: 'dialog', element: <Dialog /> },
        //     { path: 'label', element: <Label /> },
        //     { path: 'list', element: <List /> },
        //     { path: 'menu', element: <Menu /> },
        //     { path: 'pagination', element: <Pagination /> },
        //     { path: 'pickers', element: <Pickers /> },
        //     { path: 'popover', element: <Popover /> },
        //     { path: 'progress', element: <Progress /> },
        //     { path: 'radio-button', element: <RadioButtons /> },
        //     { path: 'rating', element: <Rating /> },
        //     { path: 'slider', element: <Slider /> },
        //     { path: 'snackbar', element: <Snackbar /> },
        //     { path: 'stepper', element: <Stepper /> },
        //     { path: 'switch', element: <Switches /> },
        //     { path: 'table', element: <Table /> },
        //     { path: 'tabs', element: <Tabs /> },
        //     { path: 'textfield', element: <Textfield /> },
        //     { path: 'timeline', element: <Timeline /> },
        //     { path: 'tooltip', element: <Tooltip /> },
        //     { path: 'transfer-list', element: <TransferList /> },
        //     { path: 'tree-view', element: <TreeView /> },
        //     { path: 'data-grid', element: <DataGrid /> },
        //     // EXTRA COMPONENTS
        //     { path: 'chart', element: <Charts /> },
        //     { path: 'map', element: <Map /> },
        //     { path: 'editor', element: <Editor /> },
        //     { path: 'copy-to-clipboard', element: <CopyToClipboard /> },
        //     { path: 'upload', element: <Upload /> },
        //     { path: 'carousel', element: <Carousel /> },
        //     { path: 'multi-language', element: <MultiLanguage /> },
        //     { path: 'animate', element: <Animate /> },
        //     { path: 'mega-menu', element: <MegaMenu /> },
        //     { path: 'form-validation', element: <FormValidation /> }
        //   ]
        // },
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Dashboard
// const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
// const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
// const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
// const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
// const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
// const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
// const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
// const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
// const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
// const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
// const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
// const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
// const ProjectCreate = Loadable(lazy(() => import('../components/dashboard/ProjectCreate')));
// const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
// const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
// const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
// const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
// const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main
const ComingSoon = Loadable(lazy(() => import('../components/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../components/Maintenance')));
const Pricing = Loadable(lazy(() => import('../components/Pricing')));
const Payment = Loadable(lazy(() => import('../components/Payment')));
const Page500 = Loadable(lazy(() => import('../components/Page500')));
const NotFound = Loadable(lazy(() => import('../components/Page404')));
// Components
// const ComponentsOverview = Loadable(lazy(() => import('../pages/ComponentsOverview')));
// const Color = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationColors')));
// const Typography = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationTypography')));
// const Shadows = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationShadows')));
// const Grid = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationGrid')));
// const Icons = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationIcons')));
// const Accordion = Loadable(lazy(() => import('../pages/components-overview/material-ui/Accordion')));
// const Alert = Loadable(lazy(() => import('../pages/components-overview/material-ui/Alert')));
// const Autocomplete = Loadable(lazy(() => import('../pages/components-overview/material-ui/Autocomplete')));
// const Avatar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Avatar')));
// const Badge = Loadable(lazy(() => import('../pages/components-overview/material-ui/Badge')));
// const Breadcrumb = Loadable(lazy(() => import('../pages/components-overview/material-ui/Breadcrumb')));
// const Buttons = Loadable(lazy(() => import('../pages/components-overview/material-ui/buttons')));
// const Checkbox = Loadable(lazy(() => import('../pages/components-overview/material-ui/Checkboxes')));
// const Chip = Loadable(lazy(() => import('../pages/components-overview/material-ui/chips')));
// const Dialog = Loadable(lazy(() => import('../pages/components-overview/material-ui/dialog')));
// const Label = Loadable(lazy(() => import('../pages/components-overview/material-ui/Label')));
// const List = Loadable(lazy(() => import('../pages/components-overview/material-ui/Lists')));
// const Menu = Loadable(lazy(() => import('../pages/components-overview/material-ui/Menus')));
// const Pagination = Loadable(lazy(() => import('../pages/components-overview/material-ui/Pagination')));
// const Pickers = Loadable(lazy(() => import('../pages/components-overview/material-ui/pickers')));
// const Popover = Loadable(lazy(() => import('../pages/components-overview/material-ui/Popover')));
// const Progress = Loadable(lazy(() => import('../pages/components-overview/material-ui/progress')));
// const RadioButtons = Loadable(lazy(() => import('../pages/components-overview/material-ui/RadioButtons')));
// const Rating = Loadable(lazy(() => import('../pages/components-overview/material-ui/Rating')));
// const Slider = Loadable(lazy(() => import('../pages/components-overview/material-ui/Slider')));
// const Snackbar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Snackbar')));
// const Stepper = Loadable(lazy(() => import('../pages/components-overview/material-ui/stepper')));
// const Switches = Loadable(lazy(() => import('../pages/components-overview/material-ui/Switches')));
// const Table = Loadable(lazy(() => import('../pages/components-overview/material-ui/table')));
// const Tabs = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tabs')));
// const Textfield = Loadable(lazy(() => import('../pages/components-overview/material-ui/textfield')));
// const Timeline = Loadable(lazy(() => import('../pages/components-overview/material-ui/Timeline')));
// const Tooltip = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tooltip')));
// const TransferList = Loadable(lazy(() => import('../pages/components-overview/material-ui/transfer-list')));
// const TreeView = Loadable(lazy(() => import('../pages/components-overview/material-ui/TreeView')));
// const DataGrid = Loadable(lazy(() => import('../pages/components-overview/material-ui/data-grid')));
// //
// const Charts = Loadable(lazy(() => import('../pages/components-overview/extra/Charts')));
// const Map = Loadable(lazy(() => import('../pages/components-overview/extra/Map')));
// const Editor = Loadable(lazy(() => import('../pages/components-overview/extra/Editor')));
// const CopyToClipboard = Loadable(lazy(() => import('../pages/components-overview/extra/CopyToClipboard')));
// const Upload = Loadable(lazy(() => import('../pages/components-overview/extra/Upload')));
// const Carousel = Loadable(lazy(() => import('../pages/components-overview/extra/Carousel')));
// const MultiLanguage = Loadable(lazy(() => import('../pages/components-overview/extra/MultiLanguage')));
// const Animate = Loadable(lazy(() => import('../pages/components-overview/extra/animate')));
// const MegaMenu = Loadable(lazy(() => import('../pages/components-overview/extra/MegaMenu')));
// const FormValidation = Loadable(lazy(() => import('../pages/components-overview/extra/form-validation')));
