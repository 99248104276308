import PropTypes from 'prop-types';

import { MUISelect, MenuItem, FormControl, InputLabel, FormHelperText } from './materialUI';

// ----------------------------------------------------------------------

const FormSelect = ({ options, label, size, formControlClass, error, helperText, ...others }) => (
  <FormControl className={formControlClass} size={size} error={error}>
    <InputLabel id="form-select-helper-label" sx={{ fontSize: size }}>
      {label}
    </InputLabel>

    <MUISelect
      label={label}
      {...others}
      inputProps={{
        sx: {
          fontSize: size
        }
      }}
    >
      {options.map((option, index) => (
        <MenuItem dense={size === 'small'} key={`${index}-${option.value}`} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MUISelect>
    <FormHelperText>{helperText}</FormHelperText>
  </FormControl>
);

FormSelect.propTypes = {
  options: PropTypes.array.isRequired,

  error: PropTypes.bool,

  formControlClass: PropTypes.string,

  label: PropTypes.string.isRequired,
  size: PropTypes.string,
  helperText: PropTypes.string
};

FormSelect.defaultProps = {
  size: 'medium',
  formControlClass: '',
  helperText: '',
  error: false
};

export default FormSelect;
