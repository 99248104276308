import { useSelector } from '../../../../redux';

export const OrdersSelector = () => {
  const {
    orders,
    isLoadingOrders,
    offset: nextOffset,
    isUpdatingOrders,
    hasNext,
    updateErrors
  } = useSelector((state) => state.order);

  const { location, isLoadingLocation } = useSelector((state) => state.location);

  return {
    orders,
    location,
    isLoading: isLoadingLocation || isLoadingOrders,
    nextOffset,
    isUpdating: isUpdatingOrders,
    hasNext,
    updateErrors
  };
};
