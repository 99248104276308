import { apiUrls } from '../../../common';
import { apiPost, apiGet } from '../../../utils';

const { AUTHENTICATION, USER } = apiUrls;

export const submitLoginHelper = (requestData) => apiPost(USER.LOGIN, requestData, false);

export const refreshLoginHelper = (requestData) => apiPost(USER.REFRESH_SESSION, requestData, false);

export const submitRegisterHelper = (requestData) => apiPost(AUTHENTICATION.REGISTER, requestData, false);

export const submitResetPasswordHelper = (requestData) => apiPost(AUTHENTICATION.PASSWORD_RESET, requestData, false);

export const submitNewPasswordHelper = (requestData) => apiPost(AUTHENTICATION.NEW_PASSWORD, requestData, false);

export const GetLoggedInUserHelper = (requestData) => apiGet(USER.ME, requestData, true);

export const submitEmailVerificationHelper = (requestData) => apiPost(USER.EMAIL_VERIFICATION, requestData, false);
