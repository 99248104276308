import axios from 'axios';

import { apiURL } from '../config';
import { getToken } from './jwt';
import { getUrlWithParams } from './misc';

// todo: instead of getting token everytime from localstorage we can do something like this:
// axios.defaults.headers.common.Authorization = `Token ${accessToken}`;

// todo: exclude 'useToken' once all server-call utils usage updated to be without this flag
// eslint-disable-next-line no-unused-vars
const getHeaders = (useToken) => {
  const headers = {
    'x-build-version': true
  };

  const token = getToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

// todo: exclude 'useToken' once all server-call utils usage updated to be without this flag
// eslint-disable-next-line no-unused-vars
const getNoCacheHeaders = (useToken) => {
  const headers = {
    'Cache-Control': 'no-cache',
    'x-build-version': true
  };
  const token = getToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

export const apiPost = (requestUrl, postData, useToken = true) =>
  axios({
    method: 'post',
    url: apiURL + requestUrl,
    headers: getHeaders(useToken),
    data: postData
  });

// todo: need to update this method to append id with URL
export const apiPut = (requestUrl, postData, useToken = true) =>
  axios({
    method: 'put',
    url: apiURL + requestUrl,
    headers: getHeaders(useToken),
    data: postData
  });

export const apiPatch = (requestUrl, postData, useToken = true) =>
  axios({
    method: 'patch',
    url: apiURL + requestUrl,
    headers: getHeaders(useToken),
    data: postData
  });

export const apiGet = (requestUrl, params = {}, useToken) => {
  const completeURL = getUrlWithParams(requestUrl, params);
  return axios.get(completeURL, { headers: getNoCacheHeaders(useToken) });
};

export const apiDelete = (requestUrl, id, params = {}, useToken = true) => {
  const completeURL = getUrlWithParams(requestUrl, params);
  return axios.delete(completeURL, { headers: getNoCacheHeaders(useToken) });
};
