import { useEffect, useState } from 'react';

import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

import { Login } from '../components/authentication';
import { refreshLoginHelper } from '../components/authentication/logic/helpers';
import { getLoggedInUser, isEmpty, setLocalSession } from '../utils';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [user, setUser] = useState(getLoggedInUser());
  const [refreshTimeout, setRefreshTimeout] = useState(null);

  const handleUserUpdate = (newUser) => {
    setUser(newUser);
    setLocalSession(newUser);
  };

  useEffect(() => {
    const refreshAccessToken = async (user) => {
      try {
        const response = await refreshLoginHelper({ refresh: user.refresh });
        const { access: accessToken } = response.data;
        const refreshedUser = { ...user, access: accessToken };

        handleUserUpdate(refreshedUser);
      } catch (error) {
        handleUserUpdate({});
      }
    };

    if (isEmpty(user)) {
      return () => {};
    }

    const { access: accessToken } = user;

    const { exp } = jwtDecode(accessToken);
    const currentTime = Math.floor(Date.now() / 1000);
    if (exp > currentTime) {
      const timeToRefresh = exp - currentTime;
      const timeout = setTimeout(() => refreshAccessToken(user), timeToRefresh * 1000);
      setRefreshTimeout(timeout);
    } else {
      refreshAccessToken(user);
    }

    return () => {
      clearTimeout(refreshTimeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const isAuthenticated = !isEmpty(user);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
