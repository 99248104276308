import { apiUrls } from '../../../../common';
import { apiGet, apiPost, apiPatch } from '../../../../utils';

const { LOCATION: LOCATION_MODULE } = apiUrls;

export const getLocationHelper = (requestData) => apiGet(LOCATION_MODULE.LOCATION(), requestData);

export const createLocationHelper = (requestData) => apiPost(LOCATION_MODULE.LOCATION(), requestData);

export const updateLocationHelper = ({ id, ...requestData }) => apiPatch(LOCATION_MODULE.LOCATION(id), requestData);
