import { Link as RouterLink } from 'react-router-dom';

import { LABELS } from '../../common';
import { PATH_AUTH, PATH_DASHBOARD } from '../../routes/paths';
import { Button, Stack } from '../../utils';
import { isUserLoggedIn } from '../../utils/jwt';

const NavbarAuthButtons = () => {
  if (isUserLoggedIn()) {
    return (
      <Stack spacing={2} direction="row" justifyContent="flex-end">
        <Button variant="contained" to={PATH_DASHBOARD.root} component={RouterLink}>
          {LABELS.DASHBOARD}
        </Button>

        <Button variant="outlined" to={PATH_AUTH.logout} component={RouterLink}>
          {LABELS.LOGOUT}
        </Button>
      </Stack>
    );
  }
  return (
    <Stack spacing={2} direction="row" justifyContent="flex-end">
      <Button variant="outlined" to={PATH_AUTH.login} component={RouterLink}>
        {LABELS.LOG_IN}
      </Button>
    </Stack>
  );
};

export default NavbarAuthButtons;
