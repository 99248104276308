import { createSlice } from '@reduxjs/toolkit';

import { getSimplifiedErrors } from '../../../../utils';
import { formatInvoiceCreateErrors } from '../utils';

// ----------------------------------------------------------------------

const initialState = {
  invoice: {},
  invoices: [],
  offset: null,

  isCreatingInvoice: false,
  isLoadingInvoices: false,
  isUpdatingInvoices: false,
  isLoadingInvoice: false,

  errors: {}
};

const slice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    // Invoices list
    getInvoicesInit(state, action) {
      state.isLoadingInvoices = true;

      const { isFirstPage } = action.payload;
      if (isFirstPage) {
        state.invoices = [];
        state.offset = null;
        state.errors = {};
      }
    },

    getInvoicesFail(state, action) {
      state.isLoadingInvoices = false;
      state.errors = action.payload;
    },

    getInvoicesSuccess(state, action) {
      const { invoices, offset } = action.payload.data;
      state.isLoadingInvoices = false;
      state.invoices = invoices;
      state.offset = offset;
    },

    // Invoices Retrieve
    // eslint-disable-next-line no-unused-vars
    getInvoiceInit(state, _) {
      state.isLoadingInvoice = true;
      state.invoice = {};
      state.errors = {};
    },

    getInvoiceFail(state, action) {
      state.isLoadingInvoice = false;
      state.errors = action.payload;
    },

    getInvoiceSuccess(state, action) {
      state.isLoadingInvoice = false;
      state.invoice = action.payload.data;
    },

    // Invoice Update
    // eslint-disable-next-line no-unused-vars
    updateInvoiceInit(state, _) {
      state.isUpdatingInvoices = true;
    },

    updateInvoiceFail(state, action) {
      state.isUpdatingInvoices = false;
      state.errors = action.payload;
    },

    // eslint-disable-next-line no-unused-vars
    updateInvoiceSuccess(state, _) {
      state.isUpdatingInvoices = false;
    },

    // Invoice Create
    // eslint-disable-next-line no-unused-vars
    createInvoiceInit(state, _) {
      state.isCreatingInvoice = true;
    },

    createInvoiceFail(state, action) {
      state.isCreatingInvoice = false;
      state.errors = {
        ...action.payload.error,
        ...getSimplifiedErrors(formatInvoiceCreateErrors(action.payload.error))
      };
    },

    // eslint-disable-next-line no-unused-vars
    createInvoiceSuccess(state, _) {
      state.isCreatingInvoice = false;
    },

    resetInvoices(state) {
      state.isLoadingInvoices = false;
      state.invoices = [];
      state.errors = {};
      state.offset = null;
    },

    resetInvoice(state) {
      state.isLoadingInvoice = false;
      state.invoice = {};
      state.errors = {};
    }
  }
});

// Reducer
export const { reducer: InvoiceReducer } = slice;

// Invoice Lists Actions
export const {
  getInvoicesInit,
  getInvoicesSuccess,
  getInvoicesFail,
  resetInvoices: resetInvoicesSliceAction
} = slice.actions;

// Invoice Retrieve Actions
export const {
  getInvoiceInit,
  getInvoiceSuccess,
  getInvoiceFail,
  resetInvoice: resetInvoiceSliceAction
} = slice.actions;

// Invoice Update Actions
export const { updateInvoiceInit, updateInvoiceSuccess, updateInvoiceFail } = slice.actions;

// Invoice Create Actions
export const { createInvoiceInit, createInvoiceSuccess, createInvoiceFail } = slice.actions;
