import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import PropTypes from 'prop-types';

import { DISPLAY_MESSAGE } from '../../common';

// ----------------------------------------------------------------------

const ConfirmationDialog = (props) => {
  const { open, handleDecline, handleConfirmation, message, heading, isDisabled } = props;

  return (
    <Dialog open={open} onClose={isDisabled ? () => {} : handleDecline}>
      <DialogTitle>{heading}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isDisabled} onClick={handleDecline}>
          No
        </Button>
        <Button disabled={isDisabled} onClick={handleConfirmation} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,

  handleDecline: PropTypes.func.isRequired,
  handleConfirmation: PropTypes.func.isRequired,

  heading: PropTypes.string,
  message: PropTypes.string
};

ConfirmationDialog.defaultProps = {
  heading: null,
  message: DISPLAY_MESSAGE.ARE_YOU_SURE
};

export default ConfirmationDialog;
