import { Button, Container, Grid, Typography } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
// material

// routes
import { DISPLAY_STRINGS, LABELS } from '../../../common';
import { PATH_PAGE } from '../../../routes/paths';
//
import { MotionInView, varFadeInDown, varFadeInUp } from '../animate';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(24, 0),
  backgroundImage:
    theme.palette.mode === 'light'
      ? `linear-gradient(180deg, ${alpha(theme.palette.grey[300], 0)} 0%, ${theme.palette.grey[300]} 100%)`
      : 'none'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  marginBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    marginBottom: 0
  }
}));

// ----------------------------------------------------------------------

const HugePackElements = () => {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Grid container spacing={8} justifyContent="center">
          <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
            <MotionInView threshold={0.5} variants={varFadeInUp}>
              <img alt="light mode" src="/static/home/lightmode.png" />
            </MotionInView>
            <MotionInView threshold={0.5} variants={varFadeInDown} sx={{ top: 0, left: 0, position: 'absolute' }}>
              <img alt="dark mode" src="/static/home/darkmode.png" />
            </MotionInView>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <ContentStyle>
              <MotionInView variants={varFadeInUp} sx={{ mb: 3 }}>
                <Typography variant="h3" sx={{ color: 'primary.main' }}>
                  {LABELS.APP_NAME}
                  <Typography component="span" variant="h3" sx={{ color: 'common.black' }}>
                    &nbsp; {LABELS.DASHBOARD}
                  </Typography>
                </Typography>
              </MotionInView>

              <MotionInView variants={varFadeInUp}>
                <Typography component="p" variant="overline" sx={{ mb: 2, color: 'text.secondary' }}>
                  Purchasing, Selling, Analytics and what not!
                </Typography>
              </MotionInView>

              <MotionInView variants={varFadeInUp}>
                <Typography
                  sx={{
                    mb: 5,
                    color: isLight ? 'text.secondary' : 'common.white'
                  }}
                >
                  Total carbon emission, the percentage that compliance market eliminates and total percentage that
                  voluntary market eliminates, and point out that there’s still so much left.
                  <br />
                  Other explanation of everything, including emissions, our buisness model, our
                  features/functionalities, how we are different from others and add value to lives of people
                </Typography>
              </MotionInView>

              <MotionInView variants={varFadeInUp}>
                <Button
                  size="large"
                  color="inherit"
                  variant="outlined"
                  component={RouterLink}
                  to={PATH_PAGE.comingSoon}
                >
                  {DISPLAY_STRINGS.BE_A_PART_OF_US}
                </Button>
              </MotionInView>
            </ContentStyle>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
};

export default HugePackElements;
