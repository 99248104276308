// Misc Utils

import 'react-toastify/dist/ReactToastify.css'; // import first
import moment from 'moment';
import { toast } from 'react-toastify';

import { BACKEND_DATE_FORMAT, TOASTER_ERROR_TIME, TOASTER_SUCCESS_TIME } from '../common';
import { apiURL } from '../config';
import { camelCase, isEmpty, snakeCase } from './lodash';

export const objectToQueryParams = (params) => {
  const paramList = [];
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined && params[key] !== null) {
      paramList.push(`${key}=${encodeURIComponent(params[key])}`);
    }
  });

  return paramList.join('&');
};

export const getUrlWithParams = (requestUrl, params) => {
  const queryString = objectToQueryParams(params);
  return queryString
    ? `${apiURL}${requestUrl}?${queryString}&_=${Date.now()}`
    : `${apiURL}${requestUrl}?_=${Date.now()}`;
};

export const getCurrentUrl = (isOriginRequiredOnly = false) =>
  isOriginRequiredOnly ? window.location.origin : window.location.href;

const convertCase = (obj, method) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => convertCase(v, method));
  }
  if (obj && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [method(key)]: obj[key] ? convertCase(obj[key], method) : obj[key]
      }),
      {}
    );
  }
  return obj;
};

export const convertToSnakeCase = (obj) => convertCase(obj, snakeCase);

export const convertToCamelCase = (obj) => convertCase(obj, camelCase);

export const showErrorAlert = (message, time = TOASTER_ERROR_TIME) => toast.error(message, { autoClose: time });

export const showSuccessAlert = (message, time = TOASTER_SUCCESS_TIME) => toast.success(message, { autoClose: time });

export const sliceText = (text, count, insertDots = true) =>
  text.slice(0, count) + (text.length > count && insertDots ? '...' : '');

export const formatCurrency = (amount) => (amount ? amount.toLocaleString('en-US') : amount);

export const computeSortToBackend = (order, orderBy) => `${order === 'desc' ? '-' : ''}${orderBy}`;

export const formatBackendDateString = (date) => (date ? moment(date).format(BACKEND_DATE_FORMAT) : null);

export const getSimplifiedErrors = (errors) => {
  const simplifiedErrors = {};

  if (!isEmpty(errors)) {
    Object.keys(errors).forEach((errorKey) => {
      simplifiedErrors[errorKey] = typeof errors[errorKey] === 'string' ? errors[errorKey] : errors[errorKey]?.join();
    });
  }

  return simplifiedErrors;
};

export const toSnakeCaseStr = (string) =>
  string
    ?.replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_');
